<template>
  <div
    class="notification-child-div"
    :class="newNotfication ? 'new-notify' : 'old-notify'"
    @click ="clickHandler"
  >
    <div class="img-div" v-if="notification.data.image_url">
      <img class="image" :src="notification.data.image_url" alt="image" />
    </div>
    <div :class="notification.data.image_url ? 'details' : 'details-noimage'">
      <p class="title">{{ notification.data.title }}</p>
      <div class="desc-div">
        <div class="desc">{{ notification.data.message }}</div>
      </div>
    </div>
    <div class="actions-div" @click.stop>
      <div v-if="btnName">
        <button
          type="button"
          class="btn-login"
          aria-label="CTA"
          @click="actPopup"
        >
          {{ btnName }}
        </button>
      </div>

      <p class="delete" @click="actDelete">
        <img :src="deleteIcon" alt="delete">
      </p>
    </div>
  </div>
</template>

<script>
import appMixins from "@/mixins/appMixins";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { EventBus } from "@/main";
import deleteIcon from '@/assets/icons/delete_icon.svg';

export default {
  name: "Notification-child",
  props: ["notification"],
  data() {
    return {
      btnName: null,
      newNotfication: false,
      deleteIcon
    };
  },
  methods: {
    ...mapActions(["actGetContent"]),
    clickHandler(){
      const data = this.notification.data ;
      if( !data?.contentid )return ;

      this.$router.push( {
        name:'Home/Card',
        params:{ contentid: data.contentid }
      } )
      EventBus.$emit("closeNotification");
    },
    async actPopup() {
      const data = this.notification.data ;

      if( [ "profilepage" , "planspage" ].includes( data.action ) ) this.$router.push({ name: "Profile" }) ;

      else if( data.contentid ){
        try{
          const content = await this.actGetContent( data.contentid );

          EventBus.$emit("loadPlayer", content );
        }
        catch( e ) { console.log( e ) } ;
      } 

      EventBus.$emit("closeNotification");
    },
    actDelete() {
      let localNotification = JSON.parse(
        localStorage.getItem("fcm_notifications")
      );
      let tempArray = localNotification.map((element) => element.timestamp);
      let index = tempArray.findIndex(
        (id) => id == this.notification.timestamp
      );

      localNotification.splice(index, 1);

      localStorage.setItem(
        "fcm_notifications",
        JSON.stringify(localNotification)
      );

      EventBus.$emit("deleteNotification");
    },

    checkExpired(){
       this.actGetContent(this.notification.data.contentid)
        .then((response) => {
          // console.log("response notify ", response);

          if (response && response.contenttype === "EVENT" && response.todate) {
            let date = this.checkEventEnded(response.todate);
            console.log("date ", date)
            if (date) {
              this.btnName = "WATCH";
            }
          } else if(response && response.contenttype !=="EVENT"){
            this.btnName = "WATCH";
          }
          else{
            this.btnName = ""
          }
        })
        .catch((error) => {
          console.log(error);
        });
      // this.btnName = "WATCH";
    }
  },
  mounted() {
    let localNotificationsTemp = JSON.parse(
      localStorage.getItem("fcm_notifications_temp")
    );

    if (localNotificationsTemp && localNotificationsTemp.length > 0) {
      let tempNotification = localNotificationsTemp.find(
        (o) => o.timestamp === this.notification.timestamp
      );
      if (tempNotification) {
        this.newNotfication = true;
      } else {
        this.newNotfication = false;
      }
    } else {
      this.newNotfication = false;
    }

    // console.log("newNotfication ", this.newNotfication);
  },
  created() {
    if (
      this.notification.data.action === "profilepage" ||
      this.notification.data.action === "planspage"
    ) {
      this.btnName = "VIEW";
    } else if (!this.notification.data.action) {
      this.btnName = "";
    } else {
     this.checkExpired()
    }
  },
  mixins: [appMixins],
};
</script>

<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_fonts.scss";

.new-notify {
  background-color: $clr-bg-gray-dark-1;
}
.old-notify {
  background-color: $clr-bg-gray-dark;
}
.notification-child-div {
  display: flex;
  padding: 1vw;
  cursor: pointer;
  //   margin: 10px 0px;

  .img-div {
    width: 30%;
    position:relative;
    padding:calc(0.3 * 28.125%) 0;
    height:0;
    overflow: hidden;
    margin-right:5%;
    .image {
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      border-radius: 2px;
    }
  }
  .details {
    width: 45%;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    overflow: hidden;


  }
  .title {
    font-family: $font-family;
    font-weight: $font-weight-medium;
    color: $font-clr-gray;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .desc-div {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    .desc {
      white-space: pre-line;
      font-family: $font-family;
      font-weight: $font-weight-regular;
      color: $font-clr-gray;
      font-size: 0.8rem;
      margin-top: 10px;
    }
  }
  .details-noimage {
    width: 80%;
  }
  .actions-div {
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    button {
      width: 70%;
      padding: 3px;
      font-size: 0.85rem;
    }
    .delete {
      color: $font-clr-gray;
      font-family: $font-family;
      font-weight: $font-weight-regular;
      font-size: 0.85rem;
      cursor: pointer;
      opacity: 0.5;
      &:hover{
        opacity: 1;
      }
    }
  }
}
</style>