<template>
  <select-popup
    :size="size"
    :closePopup="closePopup"
    :showLoading="showLoading"
    class="menu-popup"
  >
    <div v-if="mobileView" class="mobile-header">
      <div class="header">Notifications</div>
      <div class="close-div" @click="closePopup()">
          <img
              src="@/assets/icons/detail_close_icon.svg"
              alt="closes"
              class="header-close"
              loading="lazy"
            />
      </div>
    </div>
    <div class="notification-div">
      <div class="header">
        <p v-if="!mobileView" class="notification-title">Notifications</p>
        <p
          class="clear-all"
          v-if="notificationList.length > 1"
          @click="actClear"
        >
          <u>Clear all</u>
        </p>
      </div>
      <div class="notify" v-if="notificationList.length > 0">
        <div
          class="notification-child"
          v-for="notification in notificationList"
          :key="notification.timestamp"
        >
          <notification-child :notification="notification" />
        </div>
      </div>
      <div v-else class="error-message">
        <p>{{ errorMessage }}</p>
      </div>
    </div>
  </select-popup>
</template>

<script>
import appMixins from "@/mixins/appMixins";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { EventBus } from "@/main";
export default {
  name: "Notification",
  props: ["size", "closePopup", "showLoaderCB"],
  data() {
    return {
      showLoading: false,
      notificationList: [],
      errorMessage: "",
      mobileView:false
    };
  },
  computed: {
    ...mapGetters(["subscriberId", "fcmNotifications"]),
  },
  watch: {
    fcmNotifications(val) {
      if (val === true) {
        this.actNotificationList();
      }
    },
  },
  methods: {
    // ...mapMutations(["commitFcmNotifications"]),
    actNotificationList() {
      // this.commitFcmNotifications(false);
      if (localStorage.getItem("fcm_notifications")) {
        let localNotifications = JSON.parse(
          localStorage.getItem("fcm_notifications")
        );
        if (localNotifications.length > 0) {
          this.notificationList = [];
          localNotifications.forEach((element) => {
            if (element.subscriberid === this.subscriberId) {
              this.notificationList.push(element);
            }
          });

          // console.log("notification list ", this.notificationList);

          if (this.notificationList.length === 0) {
            this.notificationList = [];
            this.errorMessage = "No notification";
          }
        } else {
          this.notificationList = [];
          this.errorMessage = "No notification";
        }
      } else {
        // }
        // if (this.notificationList.length === 0) {
        this.notificationList = [];
        this.errorMessage = "No notification";
      }
    },
    actClear() {
      // let localNotification = JSON.parse(
      //   localStorage.getItem("fcm_notifications")
      // );
      // let tempArray = localNotification.map(element => element.subscriberid);
      // let index = tempArray.findIndex(id => id == this.subscriberId);

      // localNotification.splice(index, 1);

      // localStorage.setItem(
      //   "fcm_notifications",
      //   JSON.stringify(localNotification)
      // );
      // let local = {}
      localStorage.removeItem("fcm_notifications");
      localStorage.removeItem("fcm_notifications_temp");

      this.actNotificationList();
    },
     mobileViewToggle(val){
      this.mobileView=val;
    }
  },
  created() {
    // if (this.fcmNotifications) {
    this.actNotificationList();
    // }

    EventBus.$on("deleteNotification", this.actNotificationList);
    EventBus.$on("planPurchasedSuccess", this.actNotificationList);

    EventBus.$on("closeNotification", (data) => {
      this.closePopup();
    });
    EventBus.$on('toggle-dropdown-view',this.mobileViewToggle);
  },
  beforeDestroy() {
    EventBus.$off("deleteNotification", this.actNotificationList);
    EventBus.$off("planPurchasedSuccess", this.actNotificationList);
    EventBus.$off('toggle-dropdown-view',this.mobileViewToggle);
  },
  components: {
    "select-popup": () =>
      import(
        /* webpackChunkName: "selectPopup" */ "@/components/Shared/templates/selectPopup.vue"
      ),
    "notification-child": () =>
      import(/* webpackChunkName: "notification" */ "./notificationChild.vue"),
  },
  mixins: [appMixins],
};
</script>

<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_fonts.scss";
@import "~sass/modules/mediaQueries";

.mobile-header{
  display:flex;
  flex-flow:row nowrap;
  justify-content: space-between;
  align-items: center;
  height:50px;
  .header{
    font-family: $font-family;
    font-weight: $font-weight-semibold;
    font-size: 1.5rem;
    color: $font-clr-gray;
  }
}
.notification-div {
  padding: 5px 0px;
  width:40vw;
  max-width:40vw;
  // max-height: 624px;
  // overflow-y: auto;
  .header {
    padding: 5px 1vw;
    display: flex;
    justify-content: space-between;
    color: $font-clr-gray;
    .notification-title{
      font-family: $font-family;
      font-weight: $font-weight-semibold;
      font-size:1.25rem;
    }
    .clear-all {
      cursor: pointer;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
  }
  .notify {
    max-height: 440px;
    overflow-y: auto;
    .notification-child {
      padding: 5px 0px;
    }
  }
  .error-message {
    padding: 5px 20px;
    color: $font-clr-gray;
    font-family: $font-family;
    font-weight: $font-weight-medium;
    p {
      font-size: 0.85rem;
    }
  }
}

@include breakpoint(max992){
    .notification-div{
      width:50vw;
      max-width: 50vw;
    }
}
@include breakpoint(max768){
    .notification-div{
      width:60vw;
      max-width: 60vw;
    }
}

@include breakpoint(max600){
    .notification-div{
      width: 70vw;
      max-width: 70vw;
    }
}
@include breakpoint(max480){
    .notification-div{
      max-width: 100%;
      width:100%;
      .header{
        justify-content: flex-end;
        padding: 5px 0;
      }
    }
}


</style>